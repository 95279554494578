// Load Styles
import "../scss/main.scss";

// Load jQuery
import "./SetJqueryGlobal.js";
import $ from "jquery";

// Load Popover lib
import "./jquery.serialtip.min.js";

// Load Bootstrap init
import { initBootstrap } from "./bootstrap.js";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: false,
  popover: false,
  toasts: false,
});

// init videocontent and videobuttons
var videoContents = document.querySelectorAll(".videocontent");

var imageTrailerDesktop = document.getElementById("imagetrailerVideo");
var imageTrailerMobile = document.getElementById("imagetrailerVideoMobile");
var imageTrailerBtn = document.getElementById("imagetrailerBtn");

// remove video element not suitable vor device
if (isMobile()) {
  imageTrailerDesktop.remove();
} else {
  imageTrailerMobile.remove();
}

var imageTrailerVideo = document.querySelector("#imagetrailer video");

// show poster of video when video is stopped
videoContents.forEach((videoContent) => {
  videoContent.addEventListener("ended", () => {
    videoContent.load();
  });
});

// handle video playback with custom buttons
imageTrailerBtn.addEventListener("click", () => {
  imageTrailerVideo.play();
});

// hide video button when video is playing
imageTrailerVideo.addEventListener("playing", () => {
  imageTrailerBtn.classList.add("playing");
});

// show video button when video is paused or stopped
imageTrailerVideo.addEventListener("ended", () => {
  imageTrailerBtn.classList.remove("playing");
});
imageTrailerVideo.addEventListener("pause", () => {
  imageTrailerBtn.classList.remove("playing");
});

// show/hide video controls
if (!isMobile()) {
  $(".video-container").on("mouseenter mouseleave", function (evt) {
    $(this).is(":hover")
      ? $("video", this).attr("controls", "controls")
      : $("video", this).removeAttr("controls");
  });
}

// first screen to content button
var btnToContent = $("#buttonToContent a");

$(window).on("scroll", function () {
  // stop video if not in viewport
  if (!isVisible(imageTrailerVideo)) {
    imageTrailerVideo.pause();
  }

  if ($(window).scrollTop() > 100) {
    btnToContent.addClass("d-none");
  } else {
    btnToContent.removeClass("d-none");
  }
});

// Sticky navbar
// =========================
// Custom function which toggles between sticky class (is-sticky)
var stickyToggle = function (sticky, stickyWrapper, scrollElement) {
  var stickyHeight = sticky.outerHeight();
  var stickyTop = stickyWrapper.offset().top;
  if (scrollElement.scrollTop() >= stickyTop) {
    stickyWrapper.height(stickyHeight);
    sticky.addClass("is-sticky");
  } else {
    sticky.removeClass("is-sticky");
    stickyWrapper.height("auto");
  }
};

// Find all data-toggle="sticky-onscroll" elements
$('[data-toggle="sticky-onscroll"]').each(function () {
  var sticky = $(this);
  var stickyWrapper = $("<div>").addClass("sticky-wrapper"); // insert hidden element to maintain actual top offset on page
  sticky.before(stickyWrapper);
  sticky.addClass("sticky");

  // Scroll & resize events
  $(window).on("scroll.sticky-onscroll resize.sticky-onscroll", function () {
    stickyToggle(sticky, stickyWrapper, $(this));
  });

  // On page load
  stickyToggle(sticky, stickyWrapper, $(window));
});

// Cookie Holder
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}
if (!getCookie("mtm_consent") && !getCookie("mtm_consent_removed")) {
  setTimeout(function () {
    $(".cookie_holder").addClass("show");
  }, 1500);
}
$(".btn_cookie_accept").click(function () {
  _paq.push(["rememberConsentGiven"]);
  $(".cookie_holder").removeClass("show");
  setTimeout(function () {
    $(".cookie_holder").remove();
  }, 200);
});
$(".btn_cookie_deny").click(function () {
  $(".cookie_holder").removeClass("show");
  _paq.push(["forgetConsentGiven"]);
  setTimeout(function () {
    $(".cookie_holder").remove();
  }, 200);
});

$(function () {
  $("[data-serialtip]").serialtip();
});

function isVisible(ele) {
  const { top, bottom } = ele.getBoundingClientRect();
  const vHeight = window.innerHeight || document.documentElement.clientHeight;

  return (top > 0 || bottom > 0) && top < vHeight;
}

function isMobile() {
  if (
    /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
}
